import * as d3 from "d3";

export default function StackedAreaChart ({
    data="",
    width = 594,
    height = 394,
    marginTop = 20,
    marginRight = 20,
    marginBottom = 20,
    marginLeft = 20
}) {
    return (
        "Stacked Area Chart"
    );
}